import React from 'react';

import {TextField, CircularProgress, Box ,Grid,Button,FormControlLabel,Checkbox,IconButton, Typography} from "@material-ui/core";
import {Table,
        Fecha,
        Accordion,
        AccordionDetails,
        AccordionSummary,
        Loader,
        Loading,
        moment,
        AttachFileIcon,
        ExpandMoreIcon,
        objestados,
        regex_solo_numeros,
        usuario_habilitado_sede,SubirArchivo,
        deshabilitar} from './index.js'

import {useAscenso} from '../../hooks/useAscenso.js'
import Main from '../../../../componentes/Main.js';

export const InfoAscenso = ({id,cabecera,setData,usuario,snackbar,puedeEditarse,cambiarStatus})=>{

    const {error,loading,loading2,procesando,datosDelTramite,status_actual_tramite,expanded,
        handleChange,acta_regional,setActa_regional,fechaAR,handleChangeFechaAR,setActa_cdn,fechaACDN,
        handleChangeFechaACDN,inicioActualizacionTramite,actualizarTramite,touched,refrescar,
        acta_cdn,refrescando,traerUrl,accederEntrevista,IniciarCerrarEntrevista,
        analitico,
        handleChangeAnaliticoCheck,
        nuevoAnalitico,setNuevoAnalitico,
        cambiarAnalitico,setCambiarAnalitico,
        nuevoExtra1,setNuevoExtra1,
        cambiarExtra1,setCambiarExtra1
    } =useAscenso(cabecera,id,setData,usuario,snackbar);
        

    const sinCambiosPendientes = ()=>{
        if((cambiarAnalitico && !nuevoAnalitico)||(cambiarExtra1 && !nuevoExtra1)){
            return false
        }else{
            return true
        }
    }    

    if(error){
        return <div>
                <p>{JSON.stringify(error)}</p>
            </div>
    }

    if(loading || loading2 || procesando){
        return <Box sx={{display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         justifyContent:'center',
                         width:'100px',
                         marginLeft:'auto',
                         marginRight:'auto',
                         minHeight:'200px' }}> 
           <CircularProgress title='Cargando...'/>
           <Typography variant='bpdy2'>
                    Cargando...
         </Typography>
       </Box>
     }

     if(!datosDelTramite){
       
        return <div>
             <Loading/>
             <p>Cargando solicitud {id}...</p>
        </div>
    }


return <Box id="solicitud-proceso" sx={{marginRight:'auto',marginLeft:'auto',padding:'5px',borderRadius:'10px',border:'solid 1px gray'}}>
            <Loader open={refrescando}/>
             {status_actual_tramite==objestados.procRegion.nombre && usuario.perfil=='region' && <Accordion expanded={expanded === 'instrucciones'} onChange={handleChange('instrucciones')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="instrucciones-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'rgb(228 228 231)'}}
                id="instrucciones-header"
                >
                    <h3>Instrucciones para procesar una solicitud de ascenso</h3> 
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        <InstruccionesAscenso/>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>}
            <Accordion expanded={expanded === 'informacion'} onChange={handleChange('informacion')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="informacion-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'rgb(228 228 231)'}}
                id="informacion-header"
                >
                    <h3>Información del ministro</h3> 
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                       <Table className='sol-anexo'>
                            <tbody className='text-sm'>
                                <tr>
                                    <td>
                                        Nombre y apellido
                                    </td>
                                    <td>
                                        {datosDelTramite.ministro}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Rango actual
                                    </td>
                                    <td>
                                        {datosDelTramite.status_actual}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'adjuntos'} onChange={handleChange('adjuntos')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="acepta-content"
                style={{borderBottom:'solid 1px gray',background:'rgb(228 228 231)'}}
                id="acepta-header"
                >
                    <h3>Adjuntos</h3>
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                        {(deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region') && <p className='color-red'>
                            Los cambios en los archivos adjuntos solo son permitidos para secretarios regionales y mientras el trámite se encuentre "En proceso regional". En este caso solo pueden ser visualizados.
                        </p>}
                        <Table className='sol-anexo'>
                            <tbody className='text-sm'>
                            {!cambiarAnalitico && <tr>
                                    <td>
                                        <p><span title='Visualice el analítico adjunto' className='cursor-pointer text-blue-400 fw-600' onClick={()=>traerUrl(datosDelTramite?.analiticoURL)}>Analítico</span></p>
                                    </td>
                                    <td>
                                        <Button 
                                        variant='contained' color='secondary'
                                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                        onClick={()=>setCambiarAnalitico(true)}>Modificar</Button>
                                    </td>
                                </tr>}
                                {cambiarAnalitico && <tr>
                                    <td>
                                        <SubirArchivo texto={!nuevoAnalitico ? "" : ''} 
                                        titulo={`Nuevo analítico`}
                                        file={nuevoAnalitico}
                                        tiposFiles={['.pdf']}
                                        maximo={5000000}
                                        setFile={(setNuevoAnalitico)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={()=>{setCambiarAnalitico(false);setNuevoAnalitico(null)}}>Cancelar</button>
                                    </td>
                                </tr>}
                                <>
                                    {!cambiarExtra1 && <tr>
                                    <td>
                                        {datosDelTramite?.extra_1URL && <p><span title='Visualice el archivo extra 1 adjunto' className='cursor-pointer text-blue-400 fw-600' onClick={()=>traerUrl(datosDelTramite?.extra_1URL)}>Archivo extra 1</span></p>}
                                    </td>
                                    {!(deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region') && <td>
                                        <Button 
                                        variant='contained' color='secondary'
                                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                        onClick={()=>setCambiarExtra1(true)}>{datosDelTramite?.extra_1URL ? 'Modificar' : 'Adjuntar nuevo archivo extra 1'}</Button>
                                    </td>}
                                </tr>}
                                {cambiarExtra1 && <tr>
                                    <td>
                                        <SubirArchivo texto={!nuevoExtra1 ? "" : ''} 
                                        titulo={`Nuevo archivo extra 1`}
                                        file={nuevoExtra1}
                                        tiposFiles={['.pdf']}
                                        maximo={5000000}
                                        setFile={(setNuevoExtra1)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={()=>{setCambiarExtra1(false);setNuevoExtra1(null)}}>Cancelar</button>
                                    </td>
                                </tr>}
                                </>
                            </tbody>
                        </Table>
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
      </Accordion>  
            <Accordion expanded={expanded === 'entrevista'} onChange={handleChange('entrevista')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="entrevista-content"
                style={{borderBottom:'solid 1px gray',justifyContent:'space-between',alignItems:'center',background:'rgb(228 228 231)'}}
                id="entrevista-header"
                >
                        <h3>Entrevista - Formulario CRE-15</h3> 
                        {datosDelTramite?.id_entrevista==undefined && <p style={{alignSelf:'center',color:'red'}}>Pendiente</p>}
                        {datosDelTramite?.id_entrevista!=undefined && <p style={{alignSelf:'center',color:'blue'}}>Realizada {datosDelTramite?.id_entrevista?.activo==true ? '(Abierta)' : '(Cerrada)'}</p>}
                </AccordionSummary>
                <AccordionDetails>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{textAlign:'left'}}>
                    {status_actual_tramite!=objestados.iniciado.nombre && datosDelTramite?.id_entrevista==undefined  &&  <Box>
                            <p>La entrevista aún no se realizó</p>
                            <br />
                            <p><span title='Visualice o complete el formulario CRE-15' className='cursor-pointer text-blue-400 fw-600' onClick={accederEntrevista}>{datosDelTramite?.id_entrevista==undefined ? 'Completar el formulario CRE-15' : 'Visualizar el formulario CRE-15'}</span></p>
                            <br />
                            <p>Si acaba de realizar la entrevista actualice el trámite haciendo <span title='Actualice luego de realizar la entrevista' className='cursor-pointer text-blue-400 fw-600' onClick={refrescar}>clic aquí</span></p>
                        </Box>} 
                        { datosDelTramite?.id_entrevista!=undefined  &&  <Table className='sol-anexo'>
                            <tbody className='text-sm'>
                                <tr>
                                    <td>
                                        Estado
                                    </td>
                                    <td>
                                        {datosDelTramite?.id_entrevista?.activo==true ? 'Activa' :  datosDelTramite?.id_entrevista?.activo==false ? 'Cerrada' : 'No se realizó la entrevista' } 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Fecha de entrevista
                                    </td>
                                    <td>
                                        {moment(Number(datosDelTramite?.id_entrevista?.fecha_entrevista)).format('DD/MM/YYYY')} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Entrevistador
                                    </td>
                                    <td>
                                        {datosDelTramite?.id_entrevista?.usuario?.nombre} 
                                    </td>
                                </tr>
                                {status_actual_tramite!=objestados.iniciado.nombre && <tr>
                                    <td>
                                        <p><span title='Visualice o complete el formulario CRE-15' className='cursor-pointer color-blue fw-600' onClick={accederEntrevista}>{datosDelTramite?.id_entrevista==undefined ? 'Completar el formulario CRE-15' : 'Visualizar el formulario CRE-15'}</span></p>
                                    </td>
                                    {datosDelTramite?.id_entrevista?.activo==true && <td>
                                        <Button startIcon={<AttachFileIcon/>} onClick={IniciarCerrarEntrevista} variant="contained" color="secondary" size='medium' title="Cerrar la entrevista">Cerrar entrevista</Button>
                                    </td>}
                                </tr>}
                            </tbody>
                        </Table>}
                    </Box>  
                </Grid>
            </Grid>
                </AccordionDetails>
            </Accordion>
            <div id="infosolicitu" className='mt-4 w-full ml-4 flex justify-center items-center'>
                    <FormControlLabel
                                    style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                                    control={
                                    <Checkbox 
                                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                        checked={analitico}
                                        onChange={handleChangeAnaliticoCheck}
                                        color="primary"
                                    />
                                    }
                                    label="Confirmo que el analítico adjunto cumple con las condiciones para el ascenso"
                        />
            </div>

            <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                        <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                        value={acta_regional} 
                        style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                        onChange={(e)=>{
                            if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                                setActa_regional(e.target.value)
                            }
                           }} label="Número de acta regional" variant="filled" />
                        <Fecha  titulo='Fecha de acta regional' 
                                fecha={fechaAR} 
                                style={{marginTop:0}}
                                ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado')}
                                disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                                setFecha={handleChangeFechaAR}/>  
                           
            </Box>

            <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                        <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                        value={acta_cdn} 
                        style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado') ? 'none' : ''}}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                        onChange={(e)=>{
                            if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                                setActa_cdn(e.target.value)
                            }
                           }} label="Número de acta CDN" variant="filled" />
                        <Fecha titulo='Fecha acta CDN' 
                                title={JSON.stringify(fechaACDN)}
                                fecha={fechaACDN}
                                style={{marginTop:0}}
                                ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado')}
                                disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                                setFecha={handleChangeFechaACDN}/> 
            </Box>
           
            {sinCambiosPendientes() && <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                {Object.entries(touched).filter(item=>item[1]==true).length>0 && <Button id="tra-action" variant='contained' color='primary' onClick={()=>inicioActualizacionTramite(actualizarTramite)}><span className=''>Grabar cambios</span></Button>}
                {!(Object.entries(touched).length > 0) && puedeEditarse && <Button id="tra-action" variant='contained' color='secondary' onClick={cambiarStatus}>Cambiar el estado</Button>}
            </Box>}
            {!sinCambiosPendientes() && <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                <p className='blink color-red'>No se puede grabar. Hay cambios pendientes</p>
            </Box>}
    </Box> 
}

const InstruccionesAscenso = ()=>{
    return <div className='instrucciones'>
        <p> 1 - Verifique los datos recibidos y la situación actual del ministro solicitante.</p>
        <p> 2 - Recuerde que cada vez que modifique uno o más campos luego debe presionar el botón "GRABAR CAMBIOS".</p>
        <p> 3 - Revise el analítico de estudios teológicos adjunto y verifique que cumpla con las condiciones para el ascenso.</p>
        <p> 4 - Si considera que el analítico presentado es válido para el ascenso confirme marcando la casilla que dice "Confirmo que el analítico adjunto..." y luego grabe los cambios.</p>
        <p> 5 - Coordine con el solicitante para realizar una entrevista para poder completar el formulario CRE-15.</p>
        <p> 6 - Presente el caso en la próxima reunión de la región y tome nota del número de acta en caso de aprobarse el ascenso.</p>
        <p> 7 - Una vez que el ascenso haya sido aprobado en la región ingrese el número de acta y la fecha de la reunión regional. Luego grabe los cambios.</p>
        <p> 8 - Vuelva a revisar los campos que ha completado. Una vez que considere que todo es correcto cambie el estado del trámite a "En proceso sede"</p>
    </div>
}



