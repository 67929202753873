import React, { useState, useEffect } from 'react';
import {Axios,
    hacerFoco,
    moment,
    Swal,
    gql,
    useQuery,
    useMutation,
    objestados,
    analizarFecha,fechasDifieren,formatearTexto,subirAdjunto} from '../componentes/modulos/index';

import {useTramites} from './useTramites.js'


export const useAscenso = (cabecera,id,setData,usuario,snackbar)=>{
    const FUNCION_BUSQUEDA_TRAMITE_GQL = gql`
    query ascenso ($id:ID!){
        ascensoInfo(id:$id){
            _id,
            id_entrevista{
                usuario{nombre},
                fecha_entrevista,
                activo,
                _id
            },
            status_actual,
            ministro,
            ministro_id,
            analiticoURL,
            extra_1URL,
            analitico_validado,
            acta_regional,
            acta_cdn,
            fecha_acta_regional,
            fecha_acta_cdn
        }
    }
    `

    const status_actual_tramite = cabecera.status[cabecera.status.length-1].texto;
    const {loading,error,data,refetch} = useQuery(FUNCION_BUSQUEDA_TRAMITE_GQL,{variables:{id:id},
        onError:(err)=>alert(err.message)})
    
    const FUNCION_MODIFICACION_TRAMITE_GQL = gql`
        mutation updateAscenso($id:ID!,$usuario:String!,$usuario_id:Int!,$analitico_validado:Boolean,
                                $acta_cdn:Int,$acta_regional:Int,$fecha_acta_cdn:String,
                                $fecha_acta_regional:String,
                                $analiticoURL:String,
                                $extra_1URL:String){
                            updateAscenso(id:$id,
                            usuario:$usuario,
                            id_usuario:$usuario_id,
                            analitico_validado:$analitico_validado,
                            acta_regional:$acta_regional,
                            acta_cdn:$acta_cdn,
                            fecha_acta_cdn:$fecha_acta_cdn,
                            fecha_acta_regional:$fecha_acta_regional,
                            analiticoURL:$analiticoURL,
                            extra_1URL:$extra_1URL)
        }
    `    
    
    const [funcionActualizacionTramite,{ data:data2, loading:loading2, error:error2 }] = useMutation(FUNCION_MODIFICACION_TRAMITE_GQL)
    const [datosDelTramite, setDatosDelTramite] = React.useState(null);
    const [analitico,setAnalitico] = useState(null)
    const [nuevoAnalitico,setNuevoAnalitico]=React.useState(null);
    const [cambiarAnalitico,setCambiarAnalitico]=React.useState(false);
    const [nuevoExtra1,setNuevoExtra1]=React.useState(null);
    const [cambiarExtra1,setCambiarExtra1]=React.useState(false);
    const [adjuntos,setAdjuntos] = React.useState([]);

    const {acta_regional,
            setActa_regional,
            acta_cdn,
            setActa_cdn,
            procesando,
            setProcesando,
            fechaAR,
            fechaACDN,
            touched,
            setTouched,
            refrescando,
            setRefrescando,
            expanded,handleChangeFechaAR,
            handleChangeFechaACDN,
            handleChange,
            //inicioActualizacionTramite, // no usamos el standard sino un custom creado aqui. Es necesario porque ahora se pueden modificar adjuntos desde administración
            setFechaACDN,traerUrl,
            setFechaAR,
            validarDatosLuegoActualizar} = useTramites()
    
    useEffect(()=>{
        
        const _datosDelTramite = data?.ascensoInfo;
    
        setDatosDelTramite(_datosDelTramite);
    
        if(_datosDelTramite){
            setAnalitico(data.ascensoInfo.analitico_validado)
            setActa_regional(_datosDelTramite.acta_regional)
            setActa_cdn(_datosDelTramite.acta_cdn)
            setFechaAR(_datosDelTramite.fecha_acta_regional ? moment(Number(_datosDelTramite.fecha_acta_regional)).format('YYYY-MM-DD'): null) 
            setFechaACDN(_datosDelTramite.fecha_acta_cdn ? moment(Number(_datosDelTramite.fecha_acta_cdn)).format('YYYY-MM-DD'): null) 
            setData(_datosDelTramite)
        }
    },[data])
    
    useEffect(()=>{
        if(datosDelTramite){

            const vector_analisis_campos = camposTocadosPorElUsuario();
            setTouched(vector_analisis_campos)
        }
    },[analitico,acta_regional,fechaAR,acta_cdn,fechaACDN,nuevoAnalitico,nuevoExtra1])
    
    const handleChangeAnaliticoCheck = (e)=>{
        setAnalitico(e.target.checked)
    }

    const validarDatosLuegoActualizarTramite = ()=>{ /*NOTE - Esta función se crea porque necesitamos ejecutar pasos que no son standard 
        entonces hacemos la funcion customizada y por lo tanto tampoco usamos la funcion inicioActualizacionTramite de useTramites sino que la creamos y customizamos aqui. 
        Normalmente se usa el standard excepto los trámites que tienen casos muy específicos*/

        const validacion_inicial = validarDatosLuegoActualizar(); // ejecuto las validaciones standard

        if(!validacion_inicial){
            return 
        }

        setProcesando(true)
        setTimeout(async () => {
            
        try{
            let url_nuevo_analitico, url_nuevo_extra_1;

            if(nuevoAnalitico){
                const analitico_subido = await subirAdjunto(nuevoAnalitico,
                    cabecera.ministro.id,
                    `analitico`,
                    adjuntos)

                if(!analitico_subido[0])
                    {
                        setProcesando(false)
                        console.log(analitico_subido[1])
                        alert('Se produjo un error al subir el nuevo analítico')
                        return false
                }else{
                    url_nuevo_analitico = analitico_subido[1]?.acta;
                    setAdjuntos([...adjuntos,url_nuevo_analitico])
                }
        
            }

            if(nuevoExtra1){
                const extra_1_subido = await subirAdjunto(nuevoExtra1,
                    cabecera.ministro.id,
                    `extra_1`,
                    adjuntos)

                if(!extra_1_subido[0])
                    {
                        setProcesando(false)
                        console.log(extra_1_subido[1])
                        alert('Se produjo un error al subir el nuevo archivo extra 1')
                        return false
                }else{
                    url_nuevo_extra_1 = extra_1_subido[1]?.acta;
                    setAdjuntos([...adjuntos,url_nuevo_extra_1])
                }                    
            }

            actualizarTramite(url_nuevo_analitico,url_nuevo_extra_1)


        }catch(err){
            alert(JSON.stringify(err))
        }
        }, 1000);
    }
    
    
    const inicioActualizacionTramite = ()=>{ // creamos esta y no usamos el standard para llamar a validarDatosLuegoActualizarTramite y ejecutar acciones y validaciones custom

        Swal.fire({
            text:"Por favor confirme la modificación",
            showCancelButton:true,
            confirmButtonText:'Modificar',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    validarDatosLuegoActualizarTramite();
                }else{
                    console.log("Se canceló la operación de la solicitud")
                }
            }
        )
    
    }

    const camposTocadosPorElUsuario = ()=>{
    
        const touched = {};
    
        if(analitico!=data.ascensoInfo.analitico_validado){
            Object.assign(touched,{analitico:true})
        }
        if(formatearTexto(acta_regional)!=formatearTexto(data.ascensoInfo.acta_regional)){
            Object.assign(touched,{acta_regional:true})
        }
        if(formatearTexto(acta_cdn)!=formatearTexto(data.ascensoInfo.acta_cdn)){
            Object.assign(touched,{acta_cdn:true})
        }
        if(fechasDifieren(data.ascensoInfo.fecha_acta_regional,fechaAR)){
            Object.assign(touched,{fecha_acta_regional:true})
        }
        if(fechasDifieren(data.ascensoInfo.fecha_acta_cdn,fechaACDN)){
            Object.assign(touched,{fecha_acta_cdn:true})
        }
        if(nuevoAnalitico){
            Object.assign(touched,{nuevoAnalitico:true})
        }
        if(nuevoExtra1){
            Object.assign(touched,{nuevoExtra1:true})
        }
        return touched
    }
    
        const IniciarCerrarEntrevista = ()=>{
    
            if (status_actual_tramite!=objestados.procRegion.nombre){
                
                snackbar.showMessage('Esta operación solo es posible si la solicitud se encuentra en estado "En proceso regional"','error')
    
                return
            }
    
            if (usuario.perfil!='region'){
                
                snackbar.showMessage('Esta operación solo es posible para el perfil de secretaría regional','error')
    
                return
            }
    
            Swal.fire({
                text:"Recuerde que al cerrar la entrevista ya no podrá ser modificada posteriormente.\n\nAsegúrese de haber revisado las respuestas y que no necesita hacer ninguna modificación \n\nPor favor confirme que desea cerrar la entrevista",
                showCancelButton:true,
                confirmButtonText:'Si, cerrar la entrevista',
                cancelButtonText:'Cancelar',
                customClass: {
                    container: 'custom-container1'
                  },
            }).then(
                resultado=>{
                    if (resultado.value){
                        cerrarEntrevista(datosDelTramite?.id_entrevista?._id)
                    }else{
                        console.log("Se canceló la operación del cierre de entrevista")
                    }
                }
            )
        }
    
        const cerrarEntrevista = async (id_entrevista)=>{
    
            if (status_actual_tramite!=objestados.procRegion.nombre){
                
                snackbar.showMessage('Esta operación solo es posible si la solicitud se encuentra en estado "En proceso regional"','error')
    
                return
            }
    
            if (usuario.perfil!='region'){
                
                snackbar.showMessage('Esta operación solo es posible para el perfil de secretaría regional','error')
    
                return
            }
    
            try{
                const {data} = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/cre15/cerrar`,{id_entrevista})
                if(data?.resultado==true){
                    Swal.fire({
                        text:`Se cerró la entrevista`,
                        icon: 'success',
                        confirmButtonColor: '#3085d6'
                        }).then(r=>{
                            refetch()
                        })  
                }else{
                    console.log(data?.resultado?.mensaje)
                    Swal.fire({
                        text:`Se produjo un error al intentar cerrar la entrevista`,
                        icon: 'error',
                        confirmButtonColor: '#3085d6'
                        })  
                }
    
            }catch(err){
                console.log(err)
                alert('Se produjo un error al cerrar la entrevista')
            }
        }
       
        const accederEntrevista = ()=>{
            
            if(datosDelTramite?.id_entrevista?.activo!=false){
                if(usuario.perfil!='region'){
    
                    snackbar.showMessage('Esta operación solo es posible si la solicitud se encuentra cerrada o el usuario tiene perfil de secretaría regional"','error')
    
                }else{}{
                    if (status_actual_tramite!=objestados.procRegion.nombre){
                    
                        snackbar.showMessage('Esta operación solo es posible si la solicitud se encuentra en estado "En proceso regional"','error')
            
                        return
                    }
                }
            }
    
            window.open(`${process.env.REACT_APP_API_UAD_BASE}/documentos/cre15/asc/${usuario.usuario}/${datosDelTramite._id}/${usuario.id_usuario}/self`, "_blank")
        } 
    
        const refrescar = ()=>{
            setRefrescando(true)
            setTimeout(() => {
                setRefrescando(false)
            }, 1000);
            refetch()
        }
    
        const actualizarTramite = async (url_analitico,url_extra_1)=>{
            try{
               const resultado = await funcionActualizacionTramite({variables:{
                    id:datosDelTramite._id,
                    usuario:usuario.nombre,
                    usuario_id:usuario.id_usuario,
                    analitico_validado: touched.analitico ? analitico : undefined,
                    acta_cdn:touched.acta_cdn ? acta_cdn ? Number(acta_cdn) : null : undefined,
                    acta_regional: touched.acta_regional ? acta_regional ? Number(acta_regional) : null : undefined,
                    fecha_acta_regional:touched.fecha_acta_regional ? analizarFecha(fechaAR) : undefined,
                    fecha_acta_cdn:touched.fecha_acta_cdn ? analizarFecha(fechaACDN) : undefined,
                    ...(url_analitico ? {analiticoURL:url_analitico} : {}),
                    ...(url_extra_1 ? {extra_1URL:url_extra_1} : {})
                },
                refetchQueries:[FUNCION_BUSQUEDA_TRAMITE_GQL],
                onCompleted:(data)=>{
                    setProcesando(false);
                    setNuevoAnalitico(null);
                    setCambiarAnalitico(false);
                    setNuevoExtra1(null);
                    setCambiarExtra1(false);
                    setTouched({})
                }})
                Swal.fire({
                    text:resultado?.data?.updateAscenso,
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    }).then(()=>{
                        setTimeout(() => {
                            hacerFoco('tra-action')
                        }, 600);
                    })    
            }catch(err){
                if(err?.networkError?.result?.errors){
                    snackbar.showMessage(err?.networkError?.result?.errors?.reduce((acum,item)=>{return `${acum} ${item.message}`},''),'error')
                   }else{
                    snackbar.showMessage(err?.message || JSON.stringify(err) || 'Error al ejecutar la operación.','error')
                   }    
            }
        }

    return {
        error,
        loading,
        loading2,
        procesando,
        datosDelTramite,
        status_actual_tramite,
        expanded,
        handleChange,
        acta_regional,
        setActa_regional,
        fechaAR,
        handleChangeFechaAR,
        setActa_cdn,
        fechaACDN,
        handleChangeFechaACDN,
        inicioActualizacionTramite,
        actualizarTramite,
        touched,
        refrescar,
        acta_cdn,
        refrescando,
        traerUrl,
        accederEntrevista,
        IniciarCerrarEntrevista,
        analitico,
        handleChangeAnaliticoCheck,
        nuevoAnalitico,setNuevoAnalitico,
        cambiarAnalitico,setCambiarAnalitico,
        nuevoExtra1,setNuevoExtra1,
        cambiarExtra1,setCambiarExtra1
    }
}