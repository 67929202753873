import Axios from 'axios'
import Swal from 'sweetalert2';

const objestados = {
    iniciado:{id:0,nombre:'Iniciado'},
    procRegion:{id:1,nombre:'En proceso regional'},
    procSede:{id:2,nombre:'En proceso sede'},
   // aprobado:{id:3,nombre:'Aprobado'},
   // rechazado:{id:4, nombre:'Rechazado'},
    auto:{id:5, nombre:'Auto'},
    tesoreria:{id:6, nombre:'En tesorería'},
    contaduria:{id:7, nombre:'En contaduría'},
    aprobado:{id:8,nombre:'Aprobado'}, //NOTE - cambiamos el id para que el aprobado o rechazado sigan siendo estados de últimas etapas
    rechazado:{id:9, nombre:'Rechazado'}
}


    const esReversionDeEstado = (objeto,status_actual_tramite)=>{

        console.log(objeto,status_actual_tramite)
        if(objeto.status){
            const objEstadosArray = Object.entries(objestados);
    
            const conf_nuevo_estado = objEstadosArray.filter(item=>item[1].nombre==objeto.status)
            const conf_estado_actual = objEstadosArray.filter(item=>item[1].nombre==status_actual_tramite)
    
            if(conf_nuevo_estado[0][1].id<conf_estado_actual[0][1].id){
                return [false,
                        'El cambio a un estado anterior requiere que ingrese una observacion. Explique el motivo de la reversión',
                        'obs_status_menor',
                        objeto.status]
            }else{
                return [true] // es porque es cambio de estado pero no una reversión
            }
    
        }else{
            return [true] // es porque no es un cambio de estado
        }
     
    
    }
    
    const esUnRechazo = (objeto)=>{
        if(objeto.status){
            if(objeto.status==objestados.rechazado.nombre){
                return [false,
                        'El rechazo de la solicitud requiere que ingrese una respuesta al ministro y una observación interna.Explique el motivo del rechazo',
                        'obs_resp_rechazo',
                         objeto.status]
            }else{
                return [true] //  es porque es un cambio de estado pero no un rechazo
            }
        }else{
            return [true] // es porque no es un cambio de estado
        }   
    }
    const esUnEstadoForzado = (objeto)=>{
        if(objeto?.forzado){
            return [false,
                'El cambio forzado de un trámite requiere una observación interna.Explique el motivo del cambio de estado forzado',
                'obs_forzado',
                 objeto.status]
        }else{
            return [true]
        }
    }
    /*
    Ministro Autorizado
    Ministro Licenciado
    Ministro Ordenado
    Ministro Ordenado Ejecutivo (NO ES AUTOMATICO se hace desde la ficha del ministro)
    Ministro Ordenado Ejecutivo Dorado (NO ES AUTOMATICO)
    */
    const seleccionarRango = async (dataTramite,dataDetalle,grabar,snackbar)=>{
    
        const rangos = [
            {inicial:'MA',id_rango:4,nombre:'Ministro Autorizado'},
            {inicial:'ML',id_rango:5,nombre:'Ministro Licenciado'},
            {inicial:'MO',id_rango:3,nombre:'Ministro Ordenado'},
            {inicial:'MOE',id_rango:1,nombre:'Ministro Ordenado Ejecutivo'},
            {inicial:'MOED',id_rango:6,nombre:'Ministro Ordenado Ejecutivo Dorado'},
        ]
    
        const rangosMap = new Map(rangos.filter(item=>item.nombre!=dataDetalle.status_actual).map(item=>[item.inicial,item.nombre]));
        const rangosObject = Object.fromEntries(rangosMap);
    
        const { value: rango } = await Swal.fire({
            text:`El rango actual del ministro ${dataDetalle.ministro} es: ${dataDetalle.status_actual}`,
            input: 'select',
            inputOptions: rangosObject,
            inputPlaceholder: 'Seleccione un nuevo rango',
            showCancelButton: true,
            
          })
          if (rango) {
    
            const rangoSeleccionado = rangos.filter(item=>item.inicial==rango)
    
            Swal.fire({
                html:`<Div>Por favor confirme el ascenso de ${dataDetalle.ministro} a ${rangoSeleccionado[0].nombre}
                </Div>`,
                showCancelButton:true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                confirmButtonText:'Modificar',
                cancelButtonText:'Cancelar'
            }).then(
                resultado=>{
                    if (resultado.value){
                        grabar({status:objestados.aprobado.nombre,
                                observaciones:`Ascendido al rango ${rangoSeleccionado[0].nombre}`,
                                ascenso:{rango:rangoSeleccionado[0],
                                        data: dataDetalle,
                                        codigo_web:dataTramite.tramiteInfo.codigo_web}})
                    }else{
                        console.log("Se canceló la operación de la solicitud")
                    }
                }
            )
          }else{
                snackbar.showMessage('No seleccionó un nuevo rango','error')
          }
    }
    
    const grabarAprobacionAscenso = async (ascenso)=>{
        try{
            const {data} = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/ascenso`,ascenso)
            if(data.resultado!=undefined){
                Swal.fire({
                    text:data?.resultado==true ? `Operación confirmada. ${data?.mensaje?.observaciones}` : data?.mensaje,
                    icon: data?.resultado==true ? 'success' : 'error',
                    confirmButtonColor: '#3085d6',
                    })  
            }else{
                throw new Error('Se produjo un error al grabar el ascenso. No se pudo confirmar el resultado del ascenso')
            }
           
        }catch(err){
            throw new Error(err?.response?.data?.message || err.message || 'Se produjo un error al grabar el ascenso')
        }
        
    }
    
    const grabarAprobacionAutonomia = async (autonomia)=>{
        try{
            const {data} = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/autonomia`,autonomia)
            if(data.resultado!=undefined){
                Swal.fire({
                    text:data?.resultado==true ? `Operación confirmada. ${data?.mensaje?.observaciones}` : data?.mensaje,
                    icon: data?.resultado==true ? 'success' : 'error',
                    confirmButtonColor: '#3085d6',
                    })  
            }else{
                throw new Error('Se produjo un error al grabar la autonomía. No se pudo confirmar el resultado de la operación')
            }
           
        }catch(err){
            throw new Error(err?.response?.data?.message || err.message || 'Se produjo un error al grabar la autonomía')
        }
        
    }
    
    const grabarAprobacionAnexo = async (anexo)=>{
        try{
            const {data} = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/anexo`,anexo)
            if(data.resultado!=undefined){
                Swal.fire({
                    text:data?.resultado==true ? `Operación confirmada. ${data?.mensaje?.observaciones}` : data?.mensaje,
                    icon: data?.resultado==true ? 'success' : 'error',
                    confirmButtonColor: '#3085d6',
                    })  
            }else{
                throw new Error('Se produjo un error al grabar el anexo. No se pudo confirmar el resultado del anexo')
            }
           
        }catch(err){
            throw new Error(err?.response?.data?.message || err.message || 'Se produjo un error al grabar el anexo')
        }
        
    }
    
    const grabarAprobacionIglFormacion = async (anexo)=>{
        try{
            const {data} = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/iglformacion`,anexo)
            if(data.resultado!=undefined){
                Swal.fire({
                    text:data?.resultado==true ? `Operación confirmada. ${data?.mensaje?.observaciones}` : data?.mensaje,
                    icon: data?.resultado==true ? 'success' : 'error',
                    confirmButtonColor: '#3085d6',
                    })  
            }else{
                throw new Error('Se produjo un error al grabar la iglesia en formación. No se pudo confirmar el resultado de la iglesia')
            }
           
        }catch(err){
            throw new Error(err?.response?.data?.message || err.message || 'Se produjo un error al grabar la iglesia en formación')
        }
        
    }
    
    const grabarAprobacionNuevoMinistro = async (nuevo_ministro)=>{
        try{
            const {data} = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/alta/tramite/ministro`,nuevo_ministro)
            if(data.resultado!=undefined){
                Swal.fire({
                    text:data?.resultado==true ? `Operación confirmada. ${data?.mensaje?.observaciones}` : data?.mensaje,
                    icon: data?.resultado==true ? 'success' : 'error',
                    confirmButtonColor: '#3085d6',
                    })  
            }else{
                throw new Error('Se produjo un error al grabar el nuevo ministro. No se pudo confirmar el resultado del anexo')
            }
           
        }catch(err){
            throw new Error(err?.response?.data?.message || err.message || 'Se produjo un error al grabar el nuevo ministro')
        }
        
    }
    
    const grabarAprobacionAfiliacion = async (afiliacion)=>{
        try{
            const {data} = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/afiliacion`,afiliacion)
            if(data.resultado!=undefined){
                Swal.fire({
                    text:data?.resultado==true ? `Operación confirmada. ${data?.mensaje?.observaciones}` : data?.mensaje,
                    icon: data?.resultado==true ? 'success' : 'error',
                    confirmButtonColor: '#3085d6',
                    })  
            }else{
                throw new Error('Se produjo un error al grabar la afiliación. No se pudo confirmar el resultado de la afiliación')
            }
           
        }catch(err){
            throw new Error(err?.response?.data?.message || err.message || 'Se produjo un error al grabar la afiliación')
        }
        
    }
    
    const tratamientodeAprobacionAscenso = (objeto,dataTramite,dataDetalle,grabar,snackbar)=>{
    
        if (objeto?.status!=objestados.aprobado.nombre){
            snackbar.showMessage('El estado seleccionado no corresponde a una aprobación','error')
            return
        }
    
        const condicionOK = objeto.status ? condicionesCumplidasParaElEstadoAscenso(dataTramite,dataDetalle,objeto) : [true];
    
        if(!condicionOK[0]){
            snackbar.showMessage(condicionOK[1],'error')
        }else{
            seleccionarRango(dataTramite,dataDetalle,grabar,snackbar)
        }
       
    }
    
    const tratamientodeAprobacionAutonomia = (objeto,dataTramite,dataDetalle,grabar,snackbar,usuario)=>{
    
        if (objeto?.status!=objestados.aprobado.nombre){
            snackbar.showMessage('El estado seleccionado no corresponde a una aprobación','error')
            return
        }
    
        const condicionOK = objeto.status ? condicionesCumplidasParaElEstadoGenerico(dataTramite,dataDetalle,objeto) : [true];
    
        if(!condicionOK[0]){
            snackbar.showMessage(condicionOK[1],'error')
        }else{
    
            Swal.fire({
                html:`<Div>Por favor confirme la aprobación
                </Div>`,
                showCancelButton:true,
                confirmButtonText:'Modificar',
                cancelButtonText:'Cancelar'
            }).then(
                resultado=>{
                    if (resultado.value){
                        grabar({status:objestados.aprobado.nombre,
                            observaciones:`La autonomía fue aprobada`,
                            autonomia:{data: dataDetalle,
                                    id_usuario:usuario.id_usuario,
                                    id_region:dataTramite.tramiteInfo.region,
                                    id_distrito:dataTramite.tramiteInfo.distrito.id,
                                    ministro:dataTramite.tramiteInfo.ministro,
                                    codigo_web:dataTramite.tramiteInfo.codigo_web}})
                            .then(resultado=>{
                                if(resultado){
                                    Swal.fire({
                                        text:'Los datos fueron grabados',
                                        icon: 'success',
                                        timer: 1000,
                                        showConfirmButton: false,
                                        })   
                                }
                            })
                    }else{
                        console.log("Se canceló la operación de aprobación")
                    }
                }
            )
        }
       
    }
    
    const tratamientodeAprobacionAfiliacion = (objeto,dataTramite,dataDetalle,grabar,snackbar,usuario)=>{
    
        if (objeto?.status!=objestados.aprobado.nombre){
            snackbar.showMessage('El estado seleccionado no corresponde a una aprobación','error')
            return
        }
    
        const condicionOK = objeto.status ? condicionesCumplidasParaAfiliacion(dataTramite,dataDetalle,objeto) : [true];
    
        if(!condicionOK[0]){
            snackbar.showMessage(condicionOK[1],'error')
        }else{
    
            Swal.fire({
                html:`<Div>Por favor confirme la aprobación
                </Div>`,
                showCancelButton:true,
                confirmButtonText:'Modificar',
                cancelButtonText:'Cancelar'
            }).then(
                resultado=>{
                    if (resultado.value){
                        grabar({status:objestados.aprobado.nombre,
                            observaciones:`La afiliacion fue aprobada`,
                            afiliacion:{data: dataDetalle,
                                    id_usuario:usuario.id_usuario,
                                    id_region:dataTramite.tramiteInfo.region,
                                    id_distrito:dataTramite.tramiteInfo.distrito.id,
                                    ministro:dataTramite.tramiteInfo.ministro,
                                    codigo_web:dataTramite.tramiteInfo.codigo_web}})
                            .then(resultado=>{
                                if(resultado){
                                    Swal.fire({
                                        text:'Los datos fueron grabados',
                                        icon: 'success',
                                        timer: 1000,
                                        showConfirmButton: false,
                                        })   
                                }
                            })
                    }else{
                        console.log("Se canceló la operación de aprobación")
                    }
                }
            )
        }
       
    }
    
    const tratamientodeAprobacionIglFormacion = (objeto,dataTramite,dataDetalle,grabar,snackbar,usuario)=>{
    
        if (objeto?.status!=objestados.aprobado.nombre){
            snackbar.showMessage('El estado seleccionado no corresponde a una aprobación','error')
            return
        }
    
        const condicionOK = objeto.status ? condicionesCumplidasParaElEstadoGenerico(dataTramite,dataDetalle,objeto) : [true];
    
        if(!condicionOK[0]){
            snackbar.showMessage(condicionOK[1],'error')
        }else{
    
            Swal.fire({
                html:`<Div>Por favor confirme la aprobación
                </Div>`,
                showCancelButton:true,
                confirmButtonText:'Modificar',
                cancelButtonText:'Cancelar'
            }).then(
                resultado=>{
                    if (resultado.value){
                        grabar({status:objestados.aprobado.nombre,
                            observaciones:`La iglesia en formación fue aprobada y creada`,
                            iglesiaFormacion:{data: dataDetalle,
                                    id_usuario:usuario.id_usuario,
                                    id_region:dataTramite.tramiteInfo.region,
                                    id_distrito:dataTramite.tramiteInfo.distrito.id,
                                    ministro:dataTramite.tramiteInfo.ministro,
                                    codigo_web:dataTramite.tramiteInfo.codigo_web}})
                            .then(resultado=>{
                                if(resultado){
                                    Swal.fire({
                                        text:'Los datos fueron grabados',
                                        icon: 'success',
                                        timer: 1000,
                                        showConfirmButton: false,
                                        })   
                                }
                            })
                    }else{
                        console.log("Se canceló la operación de aprobación")
                    }
                }
            )
        }
       
    }
    
    const tratamientodeAprobacionAnexo = (objeto,dataTramite,dataDetalle,grabar,snackbar,usuario)=>{
    
        if (objeto?.status!=objestados.aprobado.nombre){
            snackbar.showMessage('El estado seleccionado no corresponde a una aprobación','error')
            return
        }
    
        const condicionOK = objeto.status ? condicionesCumplidasParaElEstadoGenerico(dataTramite,dataDetalle,objeto) : [true];
    
        if(!condicionOK[0]){
            snackbar.showMessage(condicionOK[1],'error')
        }else{
    
            Swal.fire({
                html:`<Div>Por favor confirme la aprobación
                </Div>`,
                showCancelButton:true,
                confirmButtonText:'Modificar',
                cancelButtonText:'Cancelar'
            }).then(
                resultado=>{
                    if (resultado.value){
                        grabar({status:objestados.aprobado.nombre,
                            observaciones:`El anexo fue aprobado y creado`,
                            anexo:{data: dataDetalle,
                                    id_usuario:usuario.id_usuario,
                                    id_region:dataTramite.tramiteInfo.region,
                                    id_distrito:dataTramite.tramiteInfo.distrito.id,
                                    ministro:dataTramite.tramiteInfo.ministro,
                                    codigo_web:dataTramite.tramiteInfo.codigo_web}})
                            .then(resultado=>{
                                if(resultado){
                                    Swal.fire({
                                        text:'Los datos fueron grabados',
                                        icon: 'success',
                                        timer: 1000,
                                        showConfirmButton: false,
                                        })   
                                }
                            })
                    }else{
                        console.log("Se canceló la operación de aprobación")
                    }
                }
            )
        }
       
    }
    
    const tratamientodeAprobacionNuevoMinistro = (objeto,dataTramite,dataDetalle,grabar,snackbar,usuario)=>{
    
        if (objeto?.status!=objestados.aprobado.nombre){
            snackbar.showMessage('El estado seleccionado no corresponde a una aprobación','error')
            return
        }
    
        const condicionOK = objeto.status ? condicionesCumplidasParaElEstadoNuevoMinistro(dataTramite,dataDetalle,objeto) : [true];
    
        if(!condicionOK[0]){
            snackbar.showMessage(condicionOK[1],'error')
        }else{
    
            Swal.fire({
                html:`<Div>Por favor confirme la aprobación
                </Div>`,
                showCancelButton:true,
                confirmButtonText:'Modificar',
                cancelButtonText:'Cancelar'
            }).then(
                resultado=>{
                    if (resultado.value){
                        grabar({status:objestados.aprobado.nombre,
                            observaciones:`El nuevo ministro fue aprobado y creado`,
                            nuevo_ministro:{data: dataDetalle,
                                    id_usuario:usuario.id_usuario,
                                    id_region:dataTramite.tramiteInfo.region,
                                    id_distrito:dataTramite.tramiteInfo.distrito.id,
                                    ministro:dataTramite.tramiteInfo.ministro,
                                    codigo_web:dataTramite.tramiteInfo.codigo_web}})
                            .then(resultado=>{
                                if(resultado){
                                    Swal.fire({
                                        text:'Los datos fueron grabados',
                                        icon: 'success',
                                        timer: 1000,
                                        showConfirmButton: false,
                                        })   
                                }
                            })
                    }else{
                        console.log("Se canceló la operación de aprobación")
                    }
                }
            )
    
        }
       
    }
    
    const tratamientodeAprobacion = (objeto,dataTramite,dataDetalle,grabar,snackbar,usuario)=>{
    
        switch (dataTramite.tramiteInfo.tipo){
            case 'anexo':
                tratamientodeAprobacionAnexo(objeto,dataTramite,dataDetalle,grabar,snackbar,usuario);
                break;
            case 'ascenso':
                tratamientodeAprobacionAscenso(objeto,dataTramite,dataDetalle,grabar,snackbar);
                break;
            case 'autonomia':
                    tratamientodeAprobacionAutonomia(objeto,dataTramite,dataDetalle,grabar,snackbar,usuario);
                    break; 
            case 'afiliacion':
                tratamientodeAprobacionAfiliacion(objeto,dataTramite,dataDetalle,grabar,snackbar,usuario);
                break;                 
            case 'nuevo_ministro':
                tratamientodeAprobacionNuevoMinistro(objeto,dataTramite,dataDetalle,grabar,snackbar,usuario);
                break;
            case 'igl_formacion':
                    tratamientodeAprobacionIglFormacion(objeto,dataTramite,dataDetalle,grabar,snackbar,usuario);
                    break;           
            default :
                alert('No se pudo identificar el tipo de trámite')
        }
          
    }
    
    
    const mapearTipo = (tipo)=>{
        switch(tipo){
          case 'anexo' : return 'Anexo'
          case 'afiliacion' : return 'Afiliación'
          case 'autonomia' : return 'Autonomía'
          case 'nuevo_patrocinado' : return 'Ministro presentado'
          case 'nuevo_ministro' : return 'Primera credencial'
          case 'ascenso' : return 'Ascenso'
          case 'igl_formacion' : return 'Igl. en formación'
          default : return 'Desconocido'
        }
      }
    
      const estadosPosiblesConComentarios = (tramite,usuario)=>{

        const lista_usuarios_sede_habilitados = process.env.REACT_APP_USUARIOS_SEDE_HABILITADOS;
    
        const lista_vector = lista_usuarios_sede_habilitados.split(',');
    
        const usuario_es_sede_habilitado = lista_vector.some(item=>item==usuario.id_usuario)
    
        const status_actual = tramite.status[tramite.status.length-1].texto;
    
        if(tramite.tipo=='afiliacion'){
            if(usuario?.rol_contabilidad && usuario?.rol_contabilidad>50){ 
                // perfil área contable rol tesoreria entre 51 y 100
                // este trámite primero lo autoriza un perfil alto
                // solo puede autorizar para que pase a contaduría (o rechazar, pero no aprobar) 
                // y lo terminen aprobando o rechazando desde contaduría
                switch(status_actual){
                    case objestados.iniciado.nombre : return {estados:[[status_actual,status_actual]],comentario:'...'}
                    case objestados.contaduria.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario con perfil de contaduría'}
                    case objestados.tesoreria.nombre: return {estados:[[status_actual,status_actual],
                                                            [objestados.contaduria.nombre,objestados.contaduria.nombre],
                                                            [objestados.rechazado.nombre,objestados.rechazado.nombre]]}
                    case objestados.aprobado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El trámite se encuentra finalizado'}
                    case objestados.rechazado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El trámite se encuentra finalizado'}
                    default: return {estados:[['Inválido','Inválido']],comentario:'El trámite requiere un usuario con perfil del área contable'}
                }
            }else{
    
                if(usuario?.rol_contabilidad){ 
                    // perfil área contable rol contaduría entre 1 y 50 
                    // es el perfil que carga el acta_afip y puede aprobar o rechazar
                    switch(status_actual){
                        case objestados.iniciado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario con perfil de tesorería'}
                        case objestados.contaduria.nombre: return {estados:[[status_actual,status_actual],
                            [objestados.contaduria.nombre,objestados.contaduria.nombre],
                            [objestados.aprobado.nombre,objestados.aprobado.nombre],
                            [objestados.rechazado.nombre,objestados.rechazado.nombre]]}
                        case objestados.tesoreria.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario con perfil de tesorería'}
                        case objestados.aprobado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El trámite se encuentra finalizado'}
                        case objestados.rechazado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El trámite se encuentra finalizado'}
                        default: return {estados:[['Inválido','Inválido']],comentario:'El trámite requiere un usuario con perfil del área contable'}
                    }                
                }
    
                return {estados:[['Inválido','Inválido']],comentario:'El trámite requiere un usuario con perfil del área contable'}
            }
        }
    
        if(usuario.perfil=='region'){
            switch(status_actual){
                //case objestados.iniciado.nombre : return {estados:[[status_actual,status_actual],[objestados.procRegion.nombre,objestados.procRegion.nombre]]}
                case objestados.iniciado.nombre : return {estados:[[status_actual,status_actual]],comentario:'...'}
                case objestados.procRegion.nombre : return {estados:[[status_actual,status_actual],[objestados.iniciado.nombre,objestados.iniciado.nombre],[objestados.rechazado.nombre,objestados.rechazado.nombre],[objestados.procSede.nombre,objestados.procSede.nombre]]}
                case objestados.procSede.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario sede autorizado'}
                case objestados.aprobado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario sede autorizado'}
                case objestados.rechazado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El trámite se encuentra finalizado'}
                default : return {estados:[['Indefinido','Indefinido']],comentario:'El estado del trámite no está definido'}
            }
        }
    
        if(usuario.perfil=='sede'){
            if(usuario_es_sede_habilitado){
                switch(status_actual){
                    case objestados.iniciado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario regional'}
                    case objestados.procRegion.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario regional'}
                    case objestados.procSede.nombre: return {estados:[[status_actual,status_actual],
                                                            [objestados.procRegion.nombre,objestados.procRegion.nombre],
                                                            [objestados.aprobado.nombre,objestados.aprobado.nombre],
                                                            [objestados.rechazado.nombre,objestados.rechazado.nombre]]}
                    case objestados.aprobado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El trámite se encuentra finalizado'}
                    case objestados.rechazado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El trámite se encuentra finalizado'}
                    default : return {estados:[['Indefinido','Indefinido']],comentario:'El estado del trámite no está definido'}
                }
            }else{
                switch(status_actual){
                    case objestados.iniciado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario regional'}
                    case objestados.procRegion.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario regional'}
                    case objestados.procSede.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario sede autorizado'}
                    case objestados.aprobado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El estado actual require de un usuario sede autorizado'}
                    case objestados.rechazado.nombre : return {estados:[[status_actual,status_actual]],comentario:'El trámite se encuentra finalizado'}
                    default : return {estados:[['Indefinido','Indefinido']],comentario:'El estado del trámite no está definido'}
                }
            }
        }
    }
    
    const condicionesCumplicadasParaElEstado = (cabecera,detalle,values)=>{
        
        switch (cabecera?.tramiteInfo?.tipo){
            case 'anexo':
            case 'nuevoT1':
            case 'autonomia':
            case 'igl_formacion':
                return condicionesCumplidasParaElEstadoGenerico(cabecera,detalle,values)
            case 'afiliacion':
                    return condicionesCumplidasParaAfiliacion(cabecera,detalle,values)            
            case 'ascenso':
                return condicionesCumplidasParaElEstadoAscenso(cabecera,detalle,values)
            case 'nuevo_ministro':
                return condicionesCumplidasParaElEstadoNuevoMinistro(cabecera,detalle,values)
            default : return [false,'No se pudo identificar el tipo de trámite']
        }
    
    }
    
    const condicionesCumplidasParaElEstadoAscenso = (cabecera,detalle,values)=>{
        
        const tramite = cabecera.tramiteInfo;
        const ascenso = detalle;
    
        const status_nuevo = values.status;
    
        switch(status_nuevo){
            case objestados.iniciado.nombre : return [true]
            case objestados.procRegion.nombre : return [true]
            case objestados.procSede.nombre : 
                                    if(
                                        ascenso.analitico_validado==true
                                    && ascenso.fecha_acta_regional
                                    && (ascenso?.id_entrevista!=undefined && ascenso?.id_entrevista?.activo==false)  
                                    && ascenso.acta_regional){
                                            return [true]
                                    }else{
                                        
                                        let erroresParalelo = [];
    
                                        if(ascenso.analitico_validado==false){
                                            erroresParalelo.push('No se validó el analítico')
                                        }
                                        
                                        if(!ascenso.acta_regional){
                                            erroresParalelo.push('Falta suministrar el número de acta regional')
                                        }
                                        
                                        if(!ascenso.fecha_acta_regional){
                                            erroresParalelo.push('Falta suministrar la fecha del acta regional')
                                        }  
                                        
                                        if(ascenso.id_entrevista==undefined){
                                            erroresParalelo.push('Falta realizar la entrevista')
                                        }
                                        
                                        if(ascenso?.id_entrevista?.activo==true){
                                            erroresParalelo.push('Aún no se ha cerrado la entrevista. Debe cerrarla antes de pasar la solicitud a sede')
                                        }
    
                                        if(erroresParalelo.length>0){
                                            return [false,erroresParalelo.reduce((ac,item)=>{
                                                return `${ac} \n${item}`
                                            },'')]
                                        }
                                    }
                            
            case objestados.aprobado.nombre :  
                if(
                    ascenso.analitico_validado==true
                    && ascenso.fecha_acta_cdn 
                    && ascenso.fecha_acta_regional
                    && (ascenso?.id_entrevista!=undefined && ascenso?.id_entrevista?.activo==false)  
                    && ascenso.acta_regional 
                    && ascenso.acta_cdn){
                        return [true]
                }else{
    
                    let erroresParalelo = [];
    
                    if(ascenso.analitico_validado==false){
                        erroresParalelo.push('No se validó el analítico')
                    }
                    
                    if(!ascenso.acta_regional){
                        erroresParalelo.push('Falta suministrar el número de acta regional')
                    }
                    
                    if(!ascenso.acta_cdn){
                        erroresParalelo.push('Falta suministrar el número de acta CDN')
                    }
                    
                    if(!ascenso.fecha_acta_cdn){
                        erroresParalelo.push('Falta suministrar la fecha de acta CDN')
                    }
                    
                    if(!ascenso.fecha_acta_regional){
                        erroresParalelo.push('Falta suministrar la fecha de acta regional')
                    }
    
                    if(ascenso.id_entrevista==undefined){
                        erroresParalelo.push('Falta realizar la entrevista')
                    }
                    
                    if(ascenso?.id_entrevista?.activo==true){
                        erroresParalelo.push('Aún no se ha cerrado la entrevista. Debe cerrarla antes de pasar la solicitud a sede')
                    }
                    
                    if(erroresParalelo.length>0){
                        return [false,erroresParalelo.reduce((ac,item)=>{
                            return `${ac} \n${item}`
                        },'')]
                    }
                }
    
            case objestados.rechazado.nombre :  
                if(values.respuesta && values.observaciones){
                        return [true]
                }else{
                   if(!values.respuesta){
                        return [false,'Para rechazar la solicitud escriba una respuesta al ministro']
                   }else{
                        return [false,'Para rechazar la solicitud escriba una observación interna']
                }
                }
                               
            default : return [false,`El estado no se pudo identificar correctamente para validar el cambio.El estado ${status_nuevo} no está tipificado.`]
        }
    }
    
    const condicionesCumplidasParaElEstadoNuevoMinistro = (cabecera,detalle,values)=>{
        
        const tramite = cabecera.tramiteInfo;
        const nuevo_ministro = detalle;
    
        const status_nuevo = values.status;
    
        switch(status_nuevo){
            case objestados.iniciado.nombre : return [true]
            case objestados.procRegion.nombre : return [true]
            case objestados.procSede.nombre : 
                                    if(
                                        nuevo_ministro.analitico_validado==true
                                    && nuevo_ministro.entrevista_validada==true
                                    && nuevo_ministro.condiciones_validadas==true
                                    && nuevo_ministro.referencias_validadas==true
                                    && (nuevo_ministro?.id_entrevista!=undefined && nuevo_ministro?.id_entrevista?.activo==false)  
                                    && (nuevo_ministro?.referencias.pastor.fecha_respuesta!=undefined && nuevo_ministro?.referencias.pastor.activo==false)  
                                    && (nuevo_ministro?.referencias.anciano.fecha_respuesta!=undefined && nuevo_ministro?.referencias.anciano.activo==false)  
                                    && ((nuevo_ministro?.referencias.conyuge.fecha_respuesta!=undefined && nuevo_ministro?.referencias.conyuge.activo==false && nuevo_ministro?.personales.conyuge) || !(nuevo_ministro?.personales.conyuge))  
                                    && nuevo_ministro.fecha_acta_regional
                                    && nuevo_ministro.acta_regional){
                                            return [true]
                                    }else{
                                        let erroresParalelo = [];
    
                                        if(nuevo_ministro?.referencias.pastor.fecha_respuesta==undefined || nuevo_ministro?.referencias.pastor.activo==true){
                                            erroresParalelo.push('Falta recibir las referencias del pastor')
                                        }
                                        
                                        if(nuevo_ministro?.referencias.anciano.fecha_respuesta==undefined || nuevo_ministro?.referencias.anciano.activo==true){
                                            erroresParalelo.push('Falta recibir las referencias del anciano')
                                        }
                                        
                                        if((nuevo_ministro?.referencias.conyuge.fecha_respuesta==undefined || nuevo_ministro?.referencias.conyuge.activo==true) && nuevo_ministro?.personales.conyuge){
                                            erroresParalelo.push('Falta recibir las referencias del cónyuge')
                                        }
                                        
                                        if(nuevo_ministro.id_entrevista==undefined){
                                            erroresParalelo.push('Falta realizar la entrevista')
                                        }
                                        
                                        if(nuevo_ministro?.id_entrevista?.activo==true){
                                            erroresParalelo.push('Aún no se ha cerrado la entrevista. Debe cerrarla antes de pasar la solicitud a sede')
                                        }
                        
                                        if(nuevo_ministro.analitico_validado==false){
                                            erroresParalelo.push('No se validó el analítico')
                                        }
                                        
                                        if(!nuevo_ministro.acta_regional){
                                            erroresParalelo.push('Falta suministrar el número de acta regional')
                                        }
                                        
                                        if(!nuevo_ministro.fecha_acta_regional){
                                            erroresParalelo.push('Falta suministrar la fecha de acta regional')
                                        }
                                        
                                        if(nuevo_ministro.referencias_validadas==false){
                                            erroresParalelo.push('No se confirmó el check de las referencias')
                                        }
                                        
                                        if(nuevo_ministro.entrevista_validada==false){
                                            erroresParalelo.push('No se confirmó el check de la entrevista')
                                        }
                                        
                                        if(nuevo_ministro.condiciones_validadas==false){
                                            erroresParalelo.push('No se confirmó el check de las condiciones del solicitante')
                                        }
    
                                        if(erroresParalelo.length>0){
                                            return [false,erroresParalelo.reduce((ac,item)=>{
                                                return `${ac} \n${item}`
                                            },'')]
                                        }
                                    }
                            
            case objestados.aprobado.nombre :  
                if(
                    nuevo_ministro.analitico_validado==true
                    && nuevo_ministro.referencias_validadas==true
                    && nuevo_ministro.condiciones_validadas==true
                    && nuevo_ministro.entrevista_validada==true
                    && nuevo_ministro.fecha_acta_cdn 
                    && nuevo_ministro.fecha_acta_regional
                    && nuevo_ministro.acta_regional 
                    && (nuevo_ministro?.referencias.pastor.fecha_respuesta!=undefined && nuevo_ministro?.referencias.pastor.activo==false)  
                    && (nuevo_ministro?.referencias.anciano.fecha_respuesta!=undefined && nuevo_ministro?.referencias.anciano.activo==false)  
                    && ((nuevo_ministro?.referencias.conyuge.fecha_respuesta!=undefined && nuevo_ministro?.referencias.conyuge.activo==false && nuevo_ministro?.personales.conyuge) || !(nuevo_ministro?.personales.conyuge))  
                    && (nuevo_ministro?.id_entrevista!=undefined && nuevo_ministro?.id_entrevista?.activo==false)  
                    && nuevo_ministro.acta_cdn){
                        return [true]
                }else{
    
                    let erroresParalelo = [];
    
                    if(nuevo_ministro?.referencias.pastor.fecha_respuesta==undefined || nuevo_ministro?.referencias.pastor.activo==true){
                        erroresParalelo.push('Falta recibir las referencias del pastor')
                    }
                    
                    if(nuevo_ministro?.referencias.anciano.fecha_respuesta==undefined || nuevo_ministro?.referencias.anciano.activo==true){
                        erroresParalelo.push('Falta recibir las referencias del anciano')
                    }
                    
                    if((nuevo_ministro?.referencias.conyuge.fecha_respuesta==undefined || nuevo_ministro?.referencias.conyuge.activo==true) && nuevo_ministro?.personales.conyuge){
                        erroresParalelo.push('Falta recibir las referencias del cónyuge')
                    }
                    
                    if(nuevo_ministro.id_entrevista==undefined){
                        erroresParalelo.push('Falta realizar la entrevista')
                    }
                    
                    if(nuevo_ministro?.id_entrevista?.activo==true){
                        erroresParalelo.push('Aún no se ha cerrado la entrevista. Debe cerrarla antes de pasar la solicitud a sede')
                    }
    
                    if(nuevo_ministro.analitico_validado==false){
                        erroresParalelo.push('No se validó el analítico')
                    }
                    
                    if(!nuevo_ministro.acta_regional){
                        erroresParalelo.push('Falta suministrar el número de acta regional')
                    }
                    
                    if(!nuevo_ministro.acta_cdn){
                        erroresParalelo.push('Falta suministrar el número de acta CDN')
                    }
                    
                    if(!nuevo_ministro.fecha_acta_cdn){
                        erroresParalelo.push('Falta suministrar la fecha de acta CDN')
                    }
                    
                    if(!nuevo_ministro.fecha_acta_regional){
                        erroresParalelo.push('Falta suministrar la fecha de acta regional')
                    }
                    
                    if(nuevo_ministro.referencias_validadas==false){
                        erroresParalelo.push('No se confirmó el check de las referencias')
                    }
                    
                    if(nuevo_ministro.entrevista_validada==false){
                        erroresParalelo.push('No se confirmó el check de la entrevista')
                    }
                    
                    if(nuevo_ministro.condiciones_validadas==false){
                        erroresParalelo.push('No se confirmó el check de las condiciones del solicitante')
                    }
    
                    if(erroresParalelo.length>0){
                        return [false,erroresParalelo.reduce((ac,item)=>{
                            return `${ac} \n${item}`
                        },'')]
                    }
                }
    
            case objestados.rechazado.nombre :  
                if(values.respuesta && values.observaciones){
                        return [true]
                }else{
                   if(!values.respuesta){
                        return [false,'Para rechazar la solicitud escriba una respuesta al ministro']
                   }else{
                        return [false,'Para rechazar la solicitud escriba una observación interna']
                }
                }
                               
            default : return [false,`El estado no se pudo identificar correctamente para validar el cambio.El estado ${status_nuevo} no está tipificado.`]
        }
    }
    
    const condicionesCumplidasParaElEstadoGenerico = (cabecera,detalle,values,usuario)=>{
        
        const tramite = cabecera.tramiteInfo;
        const ascenso = detalle;
    
        const status_nuevo = values.status;
    
        switch(status_nuevo){
            case objestados.iniciado.nombre : return [true]
            case objestados.procRegion.nombre : return [true]
            case objestados.procSede.nombre : 
                                    if(ascenso.fecha_acta_regional
                                    && ascenso.acta_regional){
                                            return [true]
                                    }else{
                                        if(!ascenso.acta_regional){
                                            return [false,'Falta suministrar el número de acta regional']
                                        }else if(!ascenso.fecha_acta_regional){
                                            return [false,'Falta suministrar la fecha del acta regional']
                                        }                                 
                                    }
                            
            case objestados.aprobado.nombre :  
                if(ascenso.fecha_acta_cdn 
                    && ascenso.fecha_acta_regional
                    && ascenso.acta_regional 
                    && ascenso.acta_cdn){
                        return [true]
                }else{
                    if(!ascenso.acta_regional){
                        return [false,'Falta suministrar el número de acta regional']
                    }else if(!ascenso.acta_cdn){
                        return [false,'Falta suministrar el número de acta CDN']
                    }else if(!ascenso.fecha_acta_cdn){
                        return [false,'Falta suministrar la fecha de acta CDN']
                    }else if(!ascenso.fecha_acta_regional){
                        return [false,'Falta suministrar la fecha de acta regional']
                    }else{
                        return [false,'No se pudo determinar las condiciones de aprobación']
                    }
                }
    
            case objestados.rechazado.nombre :  
                if(values.respuesta && values.observaciones){
                        return [true]
                }else{
                   if(!values.respuesta){
                        return [false,'Para rechazar la solicitud escriba una respuesta al ministro']
                   }else{
                        return [false,'Para rechazar la solicitud escriba una observación interna']
                }
                }
                               
            default : return [false,`El estado no se pudo identificar correctamente para validar el cambio.El estado ${status_nuevo} no está tipificado.`]
        }
    }
    
    const condicionesCumplidasParaAfiliacion= (cabecera,detalle,values,usuario)=>{
        
        const tramite = cabecera.tramiteInfo;
        const afiliacion = detalle;
    
        const status_nuevo = values.status;
    
        switch(status_nuevo){
            case objestados.iniciado.nombre : return [true]
            case objestados.tesoreria.nombre : return [true]
            case objestados.contaduria.nombre : 
                                    if(afiliacion.autorizado_tesoreria==true){
                                        return [true]
                                     }else{
                                        return [false,'Para avanzar a contaduría falta confirmar la autorización desde tesorería']
                                    }
            case objestados.aprobado.nombre :  
                if(afiliacion.acta_afip && afiliacion.fecha_acta_afip && afiliacion.autorizado_tesoreria==true
                    ){
                        return [true]
                }else{
                    if(!afiliacion.acta_afip){
                        return [false,'Falta adjuntar el archivo de acta de la afip']
                    }
                    if(!afiliacion.fecha_acta_afip){
                        return [false,'Falta la fecha de acta de la afip']
                    }
                    else if(afiliacion.autorizado_tesoreria!=true){
                        return [false,'Falta autorizar la solicitud desde tesorería']
                    }
                    else{
                        return [false,'No se pudo determinar las condiciones de aprobación']
                    }
                }
    
            case objestados.rechazado.nombre :  
                if(values.respuesta && values.observaciones){
                        return [true]
                }else{
                   if(!values.respuesta){
                        return [false,'Para rechazar la solicitud escriba una respuesta al ministro']
                   }else{
                        return [false,'Para rechazar la solicitud escriba una observación interna']
                }
                }
                               
            default : return [false,`El estado no se pudo identificar correctamente para validar el cambio.El estado ${status_nuevo} no está tipificado.`]
        }
    }
    
    const mensajeSegunCambios = (cambios,cabecera)=>{
    
        const estado_actual = cabecera.tramiteInfo.status[cabecera.tramiteInfo.status.length-1].texto;
        const mensaje_estado_menor = 'Al pasar a un estado anterior se resetean los campos propios del estado actual';
    
        if(cambios.status!=estado_actual){
            switch(cambios.status){
                case objestados.iniciado.nombre : return mensaje_estado_menor
                
                case objestados.procRegion.nombre : if(estado_actual==objestados.procSede.nombre){
                                                    return mensaje_estado_menor
                                                }else{
                                                    return null
                                                }
                default : return null
                
            }
        }else{
            return null
        }
    } 
    
    const puedeEditarse = (estados)=>{
        if(estados.estados.length>1){
            return [true]
        }else{
            if(estados.comentario){
                return [false,estados.comentario]
            }else{
                return [false,'No es posible modificar el estado del trámite']
            }
        }
    }

    export {puedeEditarse,
            mensajeSegunCambios,
            estadosPosiblesConComentarios,
            mapearTipo,
            tratamientodeAprobacion,
            grabarAprobacionAfiliacion,
            grabarAprobacionNuevoMinistro,
            grabarAprobacionIglFormacion,
            grabarAprobacionAnexo,
            grabarAprobacionAutonomia,
            grabarAprobacionAscenso,
            seleccionarRango,
            esUnRechazo,
            esReversionDeEstado,
            objestados,esUnEstadoForzado,
            condicionesCumplicadasParaElEstado
            }






